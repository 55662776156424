<template>
<div class="msg-defail-page">
  <el-button type="primary" style="margin-bottom: 20px;" icon="el-icon-arrow-left" @click="onBack">返回</el-button>
  <div class="outer">
    <div class="title">{{this.row.title}}</div>
    <div class="name">网上农博</div>
    <div class="sendTime">{{sendTime}}</div>
    <div v-html="content"></div>
  </div>
</div>
</template>

<script>
import {localGet, formatTimes} from 'ldx-utils';
import dayjs from 'dayjs';

export default {
  data() {
    this.row = localGet('msgContent');
    this.supplierId = JSON.parse(localStorage.getItem('userInfo')).suppliersId;
    this.sendTime = formatTimes(dayjs, this.row.sendTime);
    this.messageType = this.$route.query.messageType;
    return {
      content: ''
    };
  },
  mounted() {
    if (this.row) this.content = this.row.content;
    if (!this.row.isRead) this.onRead();
  },
  methods: {
    async onRead() {
      const params = {
        messageId: this.row.id,
        messageType: this.messageType,
        supplierId: this.supplierId
      };
      await this.$axios.post(this.$api.message.read, params);
    },
    onBack() {
      this.$router.push({name: 'MsgList', params: {messageType: this.messageType}});
    }
  }
}
</script>

<style lang="scss">
.msg-defail-page{
  .outer{
    img{max-width: 100%;}
  }
  .title{margin-bottom: 50px; font-size: 30px; text-align: center;}
  .name{margin-bottom: 5px; font-size: 16px;}
  .sendTime{margin-bottom: 40px; font-size: 14px;}
}
</style>