var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "msg-defail-page" },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "primary", icon: "el-icon-arrow-left" },
          on: { click: _vm.onBack },
        },
        [_vm._v("返回")]
      ),
      _c("div", { staticClass: "outer" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(this.row.title))]),
        _c("div", { staticClass: "name" }, [_vm._v("网上农博")]),
        _c("div", { staticClass: "sendTime" }, [_vm._v(_vm._s(_vm.sendTime))]),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }